<template>
  <div class="city-node">
    <div class="bg-node"></div>
    <div class="node-tooltip">
      <p class="city-name">{{data.name}}</p>
      <p class="wrap-city-info"><span class="icon icon-people"></span><span class="city-text">节点数：</span><span class="city-value">{{data.value.split('(')[0]}}</span></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.city-node {
  display: inline-block;
  position: absolute;
  width: 0.88rem;
  height: 0.92rem;
  background: url("../assets/bg_node.png") center bottom/0.46rem no-repeat;
}

.city-node.active {
  background: url("../assets/node_active.gif") center/cover no-repeat;
}

.node-tooltip {
  display: none;
  position: absolute;
  top: 0rem;
  left: -2.2rem;
  height: 1rem;
  width: 2.1rem;
  border: 2px solid #0ff;
  background: rgba(7, 24, 50, 0.8);
}

.city-node.active .node-tooltip {
  display: block;
}

.city-name {
  margin: 0;
  line-height: 0.38rem;
  text-align: center;
  color: #fff;
  font-size: 0.16rem;
  border-bottom: 2px dotted #0ff;
}

.wrap-city-info {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.6rem;
  font-size: 0.16rem;
  color: #fff;
}

.icon-people {
  display: inline-block;
  margin-right: 0.12rem;
  width: 0.2rem;
  height: 0.16rem;
  background: url("../assets/icon_people_color.png") center/cover no-repeat;
}

.city-value {
  font-size: 0.24rem;
  color: #0ff;
}
</style>
