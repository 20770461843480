<template>
  <div class="summary-block">
    <div class="wrap-summary">
      <div class="wrap-icon">
        <span class="icon icon-block"></span>
      </div>
      <p class="summary-value">{{height}}</p>
      <p class="summary-text">区块高度</p>
    </div>
    <div class="wrap-summary">
      <div class="wrap-icon">
        <span class="icon icon-tps"></span>
      </div>
      <p class="summary-value">8048</p>
      <p class="summary-text">TPS(最高)</p>
    </div>
    <div class="wrap-summary">
      <div class="wrap-icon">
        <span class="icon icon-count"></span>
      </div>
      <p class="summary-value">851</p>
      <p class="summary-text">总合约数</p>
    </div>
    <div class="wrap-summary">
      <div class="wrap-icon">
        <span class="icon icon-deal"></span>
      </div>
      <p class="summary-value">{{deal}}</p>
      <p class="summary-text">交易总数</p>
    </div>
    <div class="wrap-summary">
      <div class="wrap-icon">
        <span class="icon icon-node"></span>
      </div>
      <p class="summary-value">120</p>
      <p class="summary-text">总节点数</p>
    </div>
    <div class="wrap-summary">
      <div class="wrap-icon">
        <span class="icon icon-active"></span>
      </div>
      <p class="summary-value">10</p>
      <p class="summary-text">活跃节点数</p>
    </div>
    <div class="wrap-summary">
      <div class="wrap-icon">
        <span class="icon icon-production"></span>
      </div>
      <p class="summary-value">{{current}}</p>
      <p class="summary-text">当前生产节点</p>
    </div>
    <div class="wrap-summary">
      <div class="wrap-icon">
        <span class="icon icon-next"></span>
      </div>
      <p class="summary-value">{{next}}</p>
      <p class="summary-text">下个生产节点</p>
    </div>
  </div>
</template>

<script>
import Chance from 'chance'
const chance = new Chance()

export default {
  data () {
    return {
      height: +localStorage.getItem('data_height') || 10000000,
      deal: +localStorage.getItem('ai_data_deal') || 10000000,
      current: chance.string({ length: 10, alpha: true, casing: 'lower' }),
      next: chance.string({ length: 10, alpha: true, casing: 'lower' })
    }
  },
  created () {
    setInterval(() => {
      this.height = +localStorage.getItem('data_height') || 10000000
      this.deal += 2
      this.current = chance.string({ length: 10, alpha: true, casing: 'lower' })
      this.next = chance.string({ length: 10, alpha: true, casing: 'lower' })

      localStorage.setItem('ai_data_deal', this.deal)
    }, 5400)
  }
}
</script>

<style scoped>
.summary-block {
  margin: auto;
  margin-bottom: 0;
  width: 9.6rem;
  height: 1.92rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
}

.wrap-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrap-icon {
  display: inline-flex;
  font-size: 0;
  justify-content: center;
  align-items: center;
  width: 0.74rem;
  height: 0.74rem;
  background: url("../assets/bg_summary.png") center/cover no-repeat;
}

.summary-value {
  margin: 0.2rem 0 0 0;
  font-size: 0.2rem;
  color: #0fff;
}

.summary-text {
  margin: 0.05rem 0 0 0;
  font-size: 0.2rem;
  color: #9cc;
  white-space: nowrap;
}

.icon {
  display: inline-block;
  width: 0.36rem;
  height: 0.36rem;
  background: center/cover no-repeat;
}

.icon-block {
  background-image: url("../assets/icon_block.png");
}

.icon-tps {
  background-image: url("../assets/icon_tps.png");
}

.icon-count {
  background-image: url("../assets/icon_count.png");
}

.icon-deal {
  background-image: url("../assets/icon_deal.png");
}

.icon-node {
  background-image: url("../assets/icon_node.png");
}

.icon-active {
  background-image: url("../assets/icon_active.png");
}

.icon-production {
  background-image: url("../assets/icon_production.png");
}

.icon-next {
  background-image: url("../assets/icon_next.png");
}
</style>
