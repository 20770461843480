<template>
  <div class="bg">
    <div class="title"></div>
    <main class="main">
      <div class="left">
        <table-block title="最新区块" :rowTitles="['高度','交易数','见证者']" :data="newData" class="bg-block"></table-block>
        <table-block title="交易" :rowTitles="['Hash', '发布者', '时间']" :data="dealData" class="bg-block" style="margin-top: 0.33rem"></table-block>
        <percent-block class="bg-block" style="margin-top: 0.33rem"></percent-block>
      </div>
      <div class="center">
        <global-map></global-map>
        <summary-block class="bg-block"></summary-block>
      </div>
      <div class="right">
        <charts-block></charts-block>
      </div>
    </main>
  </div>
</template>

<script>
import Chance from 'chance'
import TableBlock from './components/TableBlock'
import PercentBlock from './components/PercentBlock'
import ChartsBlock from './components/ChartsBlock'
import GlobalMap from './components/GlobalMap'
import SummaryBlock from './components/SummaryBlock'

const chance = new Chance()

export default {
  components: {
    TableBlock,
    PercentBlock,
    ChartsBlock,
    GlobalMap,
    SummaryBlock
  },
  data () {
    return {
      newDataId: +localStorage.getItem('data_height') || 10000000,
      newData: [],
      dealData: []
    }
  },
  created () {
    this.setNewData()
    this.setDealData()
    setInterval(() => {
      this.setNewData()
    }, 5000)
    setInterval(() => {
      this.setDealData()
    }, 5100)
  },
  methods: {
    setNewData () {
      const result = []
      this.newDataId = this.newDataId + 5
      localStorage.setItem('data_height', this.newDataId)
      for (let i = 0; i < 5; i++) {
        result.push([
          this.newDataId - i,
          chance.integer({ min: 1, max: 5 }),
          chance.string({ length: 10, alpha: true, casing: 'lower' })
        ])
      }
      this.newData = result
    },
    setDealData () {
      const result = []
      for (let i = 0; i < 5; i++) {
        result.push([
          chance.geohash({ length: 6 }) + '...',
          chance.string({ length: 10, alpha: true, casing: 'lower' }),
          i + 1 + '秒前'
        ])
      }
      this.dealData = result
    }
  }
}
</script>

<style>
html {
  font-size: 100px;
  font-family: 'Microsoft YaHei';
}

body {
  margin: auto;
  background: #04041a url("./assets/bg.png");
  width: 19.20rem;
  height: 10.80rem;
}

.echarts {
  width: 100%;
  height: 100%;
}
</style>

<style scoped>
.title {
  margin: 0 auto;
  min-width: 19.19rem;
  height: 1.57rem;
  background: url("./assets/title.png") center/contain no-repeat;
}

.left {
  margin-top: -0.42rem;
  margin-left: 0.32rem;
  width: 4.3rem;
}

.center {
  display: flex;
  flex-direction: column;
}

.right {
  margin-top: -0.42rem;
  box-sizing: border-box;
  padding: 0.34rem 0.38rem 0.2rem;
  margin-right: 0.32rem;
  width: 4.3rem;

  background: rgba(7, 24, 50, 0.8);
  border-radius: 0.06rem;
}

.bg-block {
  background: rgba(7, 24, 50, 0.8);
  border-radius: 0.06rem;
}

.main {
  padding-bottom: 0.32rem;
  display: flex;
  justify-content: space-between;
}
</style>
