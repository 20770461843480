<template>
  <div class="global-map">
    <div class="wrap-bg">
      <div class="wrap-map">
        <!-- 北京 -->
        <city-node v-for="(city, index) of cities" :key="city.name" :class="{active: index === activeIndex}" :style="city.style" :data="city"></city-node>
      </div>
      <div class="wrap-data">
        <p class="row-data" v-for="(city, index) of cities.slice(0, 5)" :key="index">
          <span class="row-number" :class="`number-${index + 1}`">{{index + 1}}</span>
          <span class="row-city">{{city.name}}</span>
          <span class="row-value">{{city.value}}</span>
        </p>
        <p></p>
        <p></p>
        <p class="row-data" v-for="(city, index) of cities.slice(5, 8)" :key="index + 6">
          <span class="row-number" :class="`number-${index + 6}`">{{index + 6}}</span>
          <span class="row-city">{{city.name}}</span>
          <span class="row-value">{{city.value}}</span>
        </p>
        <p></p>
        <p></p>
        <p></p>
        <p class="row-data" v-for="(city, index) of cities.slice(8, 10)" :key="index + 9">
          <span class="row-number" :class="`number-${index + 9}`">{{index + 9}}</span>
          <span class="row-city">{{city.name}}</span>
          <span class="row-value">{{city.value}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Chance from 'chance'
import CityNode from './CityNode'

const chance = new Chance()

export default {
  components: {
    CityNode
  },
  data () {
    return {
      activeIndex: 0,
      cities: [{
        name: '北京',
        value: '26(21.67%)',
        style: 'top: 1.6rem;left: 7.4rem'
      }, {
        name: '宁波',
        value: '16(13.33%)',
        style: 'top: 1.8rem;left: 7.4rem'
      }, {
        name: '南京',
        value: '15(12.50%)',
        style: 'top: 1.78rem;left: 7.2rem'
      }, {
        name: '长沙',
        value: '12(10.00%)',
        style: 'top: 1.81rem;left: 7.1rem'
      }, {
        name: '香港',
        value: '10(8.33%)',
        style: 'top: 2.1rem;left: 7.4rem'
      }, {
        name: '华盛顿',
        value: '8(6.67%)',
        style: 'top: 1.4rem;left: 2.2rem'
      }, {
        name: '温哥华',
        value: '6(5.00%)',
        style: 'top: 1.2rem;left: 1rem'
      }, {
        name: '东京',
        value: '5(4.17%)',
        style: 'top: 1.5rem;left: 8rem'
      }, {
        name: '新加坡',
        value: '4(3.33%)',
        style: 'top: 2.6rem;left: 7rem'
      }, {
        name: '柏林',
        value: '2(1.67%)',
        style: 'top: 1.2rem;left: 4.4rem'
      }]
    }
  },
  created () {
    setInterval(() => {
      this.activeIndex = chance.integer({ min: 0, max: 9 })
    }, 5000)
  }
}
</script>

<style scoped>
.wrap-bg {
  position: relative;
  margin-bottom: -0.42rem;
  width: 10rem;
  height: 7.5rem;
  background: url("../assets/bg_map_light.png") center bottom/contain no-repeat;
}

.wrap-map {
  position: relative;
  height: 100%;
  background: url("../assets/bg_map.png") center top/contain no-repeat;
}

.wrap-data {
  position: absolute;
  left: 0.32rem;
  bottom: 0.7rem;
  font-size: 0.14rem;
  color: #fff;
  height: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.row-data {
  margin: 0.04rem 0.5rem 0.04rem 0;
  display: flex;
  align-items: center;
}

.row-number {
  display: inline-block;
  width: 0.14rem;
  height: 0.14rem;
  line-height: 0.14rem;
  border-radius: 50%;
  background: #fff;
  color: #000231;
  text-align: center;
  font-size: 0.12rem;
  margin-right: 0.14rem;
}

.row-city {
  color: #0ff;
  margin-right: 0.05rem;
}

.number-1 {
  background: #df4e2d;
}

.number-2 {
  background: #f2a53b;
}

.number-3 {
  background: #eddc4d;
}
</style>
