<template>
  <div class="charts-block">
    <p class="block-title">CPU占用量</p>
    <v-chart class="wrap-chart" :options="cpuOpts" />
    <p class="block-title">硬盘占用量</p>
    <v-chart class="wrap-chart" :options="diskOpts" />
    <p class="block-title">内存占用量</p>
    <v-chart class="wrap-chart" :options="memoryOpts" />
    <p class="block-title">网络进出总占用量</p>
    <v-chart class="wrap-chart" :options="networkOpts" />
  </div>
</template>

<script>
import Chance from 'chance'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'

const chance = new Chance()

export default {
  components: {
    'v-chart': ECharts
  },
  data () {
    const currentTime = Date.now()
    const cpuData = [[currentTime - 8 * 30 * 1000 * 1000, this.getCpuMock()],
      [currentTime - 7 * 30 * 1000 * 1000, this.getCpuMock()],
      [currentTime - 6 * 30 * 1000 * 1000, this.getCpuMock()],
      [currentTime - 5 * 30 * 1000 * 1000, this.getCpuMock()],
      [currentTime - 4 * 30 * 1000 * 1000, this.getCpuMock()],
      [currentTime - 3 * 30 * 1000 * 1000, this.getCpuMock()],
      [currentTime - 2 * 30 * 1000 * 1000, this.getCpuMock()],
      [currentTime - 1 * 30 * 1000 * 1000, this.getCpuMock()],
      [currentTime - 0 * 30 * 1000 * 1000, this.getCpuMock()]]
    const diskData = [[currentTime - 8 * 30 * 1000 * 1000, this.getDiskMock()],
      [currentTime - 7 * 30 * 1000 * 1000, this.getDiskMock()],
      [currentTime - 6 * 30 * 1000 * 1000, this.getDiskMock()],
      [currentTime - 5 * 30 * 1000 * 1000, this.getDiskMock()],
      [currentTime - 4 * 30 * 1000 * 1000, this.getDiskMock()],
      [currentTime - 3 * 30 * 1000 * 1000, this.getDiskMock()],
      [currentTime - 2 * 30 * 1000 * 1000, this.getDiskMock()],
      [currentTime - 1 * 30 * 1000 * 1000, this.getDiskMock()],
      [currentTime - 0 * 30 * 1000 * 1000, this.getDiskMock()]]
    const memoryData = [[currentTime - 8 * 30 * 1000 * 1000, this.getMemoryMock()],
      [currentTime - 7 * 30 * 1000 * 1000, this.getMemoryMock()],
      [currentTime - 6 * 30 * 1000 * 1000, this.getMemoryMock()],
      [currentTime - 5 * 30 * 1000 * 1000, this.getMemoryMock()],
      [currentTime - 4 * 30 * 1000 * 1000, this.getMemoryMock()],
      [currentTime - 3 * 30 * 1000 * 1000, this.getMemoryMock()],
      [currentTime - 2 * 30 * 1000 * 1000, this.getMemoryMock()],
      [currentTime - 1 * 30 * 1000 * 1000, this.getMemoryMock()],
      [currentTime - 0 * 30 * 1000 * 1000, this.getMemoryMock()]]
    const networkData = [[currentTime - 8 * 30 * 1000 * 1000, this.getNetworkMock()],
      [currentTime - 7 * 30 * 1000 * 1000, this.getNetworkMock()],
      [currentTime - 6 * 30 * 1000 * 1000, this.getNetworkMock()],
      [currentTime - 5 * 30 * 1000 * 1000, this.getNetworkMock()],
      [currentTime - 4 * 30 * 1000 * 1000, this.getNetworkMock()],
      [currentTime - 3 * 30 * 1000 * 1000, this.getNetworkMock()],
      [currentTime - 2 * 30 * 1000 * 1000, this.getNetworkMock()],
      [currentTime - 1 * 30 * 1000 * 1000, this.getNetworkMock()],
      [currentTime - 0 * 30 * 1000 * 1000, this.getNetworkMock()]]
    return {
      cpuData,
      diskData,
      memoryData,
      networkData,
      currentTime,
      index: 1
    }
  },
  computed: {
    cpuOpts () {
      return {
        grid: {
          top: 15,
          bottom: 15
        },
        xAxis: {
          type: 'time',
          axisLine: {
            lineStyle: {
              color: '#183849'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#183849']
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#183849'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: ['#183849']
            }
          }
        },
        series: [{
          data: this.cpuData,
          type: 'line',
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: '#0ff'
          },
          areaStyle: {
            color: '#0ff',
            shadowBlur: 10,
            opacity: 0.1
          },
          itemStyle: {
            color: '#0ff'
          }
        }],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#0ff',
          formatter: ([params]) => params.value[1] + '%',
          textStyle: {
            color: '#09142b'
          }
        }
      }
    },
    diskOpts () {
      return {
        grid: {
          top: 15,
          bottom: 15
        },
        xAxis: {
          type: 'time',
          axisLine: {
            lineStyle: {
              color: '#183849'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#183849']
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#183849'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: ['#183849']
            }
          }
        },
        series: [{
          data: this.diskData,
          type: 'line',
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: '#69d46a'
          },
          areaStyle: {
            color: '#69d46a',
            shadowBlur: 10,
            opacity: 0.1
          },
          itemStyle: {
            color: '#69d46a'
          }
        }],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#69d46a',
          formatter: ([params]) => params.value[1] + '%',
          textStyle: {
            color: '#09142b'
          }
        }
      }
    },
    memoryOpts () {
      return {
        grid: {
          top: 15,
          bottom: 15
        },
        xAxis: {
          type: 'time',
          axisLine: {
            lineStyle: {
              color: '#183849'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#183849']
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#183849'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: ['#183849']
            }
          }
        },
        series: [{
          data: this.memoryData,
          type: 'line',
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: '#f3fd54'
          },
          areaStyle: {
            color: '#f3fd54',
            shadowBlur: 10,
            opacity: 0.1
          },
          itemStyle: {
            color: '#f3fd54'
          }
        }],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#f3fd54',
          formatter: ([params]) => params.value[1] + '%',
          textStyle: {
            color: '#09142b'
          }
        }
      }
    },
    networkOpts () {
      return {
        grid: {
          top: 15,
          bottom: 15
        },
        xAxis: {
          type: 'time',
          axisLine: {
            lineStyle: {
              color: '#183849'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#183849']
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#183849'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: ['#183849']
            }
          }
        },
        series: [{
          data: this.networkData,
          type: 'line',
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: '#3d8fe8'
          },
          areaStyle: {
            color: '#3d8fe8',
            shadowBlur: 10,
            opacity: 0.1
          },
          itemStyle: {
            color: '#3d8fe8'
          }
        }],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#3d8fe8',
          formatter: ([params]) => params.value[1] + '%',
          textStyle: {
            color: '#09142b'
          }
        }
      }
    }
  },
  created () {
    setInterval(() => {
      this.setCpuData()
      this.setDiskData()
      this.setMemoryData()
      this.setNetworkData()
      this.index = this.index + 1
    }, 5000)
  },
  methods: {
    getCpuMock () {
      return chance.integer({ min: 40, max: 65 })
    },
    setCpuData () {
      this.cpuData.shift()
      this.cpuData.push([this.currentTime + this.index * 30 * 1000 * 1000, this.getCpuMock()])
    },
    getDiskMock () {
      return chance.integer({ min: 10, max: 45 })
    },
    setDiskData () {
      this.diskData.shift()
      this.diskData.push([this.currentTime + this.index * 30 * 1000 * 1000, this.getDiskMock()])
    },
    getMemoryMock () {
      return chance.integer({ min: 30, max: 85 })
    },
    setMemoryData () {
      this.memoryData.shift()
      this.memoryData.push([this.currentTime + this.index * 30 * 1000 * 1000, this.getMemoryMock()])
    },
    getNetworkMock () {
      return chance.integer({ min: 5, max: 45 })
    },
    setNetworkData () {
      this.networkData.shift()
      this.networkData.push([this.currentTime + this.index * 30 * 1000 * 1000, this.getNetworkMock()])
    }
  }
}
</script>

<style scoped>
.block-title {
  margin: 0.08rem 0 0;
  font-size: 0.2rem;
  background-image: linear-gradient(to top, #0ff, #fff);
  background-clip: text;
  color: transparent;
}

.wrap-chart {
  width: 3.70rem;
  height: 1.85rem;
}
</style>
