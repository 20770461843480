<template>
  <div class="table-block">
    <div class="wrap-title">
      <p class="block-title">{{title}}</p>
      <span class="icon icon-more"></span>
    </div>
    <div class="table-row">
      <span class="table-text" v-for="(item, index) of rowTitles" :key="index">{{item}}</span>
    </div>
    <div class="table-row" v-for="(item, index) of data" :key="index">
      <span class="table-text">{{item[0]}}</span>
      <span class="table-value">{{item[1]}}</span>
      <span class="table-text">{{item[2]}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    rowTitles: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.table-block {
  padding: 0.38rem;
}

.wrap-title {
  margin-bottom: 0.18rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-title {
  margin: 0;
  font-size: 0.2rem;
  background-image: linear-gradient(to top, #0ff, #fff);
  background-clip: text;
  color: transparent;
}

.icon-more {
  display: inline-block;
  width: 0.24rem;
  height: 0.2rem;
  background: url("../assets/more.png") center/cover no-repeat;
}

.table-row {
  padding: 0.07rem;
  font-size: 0.16rem;
  /* TODO:  */
  border-bottom: 2px dotted #04041a;
}

.table-row span {
  display: inline-block;
  width: 33%;
}

.table-text {
  color: #fff;
}

.table-value {
  color: #0ff;
}
</style>
